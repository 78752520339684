<template>
	<div class="conContainer">
		<breadcrumb icon="/pic/zdfx1.png"></breadcrumb>

		<div class="conditionBox">


			<el-form :inline="true" :model="condition" class="demo-form-inline">

				<el-form-item label="省/市/区县:">

					<el-cascader style="width:270px;" :checkStrictly="true" :emitPath="false" v-model="condition.city_plate_id"
						size="mini" :options="citylist" :props="cascaderProp" collapse-tags clearable>
					</el-cascader>

				</el-form-item>

				<el-form-item label="地块状态:">
					<el-select v-model="type" size="mini" placeholder="全部">
						<el-option v-for="item in landstatus" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>



				<el-form-item label="用途:">
					<el-checkbox-group v-model="condition.usecate">
						<el-checkbox v-for="item in usecate" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>



				<el-form-item v-if="type === 3" label="成交日期:">

					<el-date-picker value-format="yyyy-MM-dd" v-model="condition.tradedate" style="width:230px;" size="small"
						type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" :picker-options="pickerOptions">
					</el-date-picker>

				</el-form-item>
				<el-form-item>
					<el-input v-model="condition.search" placeholder="请输入 公告号/宗地编号/地块名称/地块位置/竞得单位/楼盘" style="width:400px"
						size="small" clearable>
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-button size="mini" icon="el-icon-search" @click="getData" type="primary">查询</el-button>
				</el-form-item>

			</el-form>

		</div>
		<div v-if="mode === 'map'" class="map">
			<!-- <mapmode :center="mapcenter" :searchdata="searchData" :singledata="singledata" :areaCountData="areaCountData" 
			:mapdata="mapdata" @bounds="getBounds"></mapmode> -->

			<leafletmapmode  :center="mapcenter" :searchdata="searchData" :singledata="singledata" :areaCountData="areaCountData"
				:mapdata="mapdata"   @bounds="getBounds"></leafletmapmode>

		</div>


	</div>
</template>
<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import TableData from '@/components/common/TableData.vue'
import SelectForm from '@/components/common/SelectForm.vue'

import Mapmode from '@/components/common/Mapmode.vue'
import { isgaode } from "@/settings.js";

import leafletmapmode from '@/components/leaflet/leafletmapmode.vue'

import { nextLevelCity, getCityLnglat, useYearList, useCateList, FiledData, table, downloadExcel, citylist, downloadZip, mapData, getDetal,mapCount } from '@/api/data.js'
export default {

	data() {
		return {
			areaCountData: [
			],
			type: 0,
			landstatus: [
				{
					label: '请选择',
					value: 0
				},
				{
					label: '拟上市',
					value: 1
				},
				{
					label: '正在公告',
					value: 2
				},
				{
					label: '土拍成交',
					value: 3
				}],
			singledata: {}, //地图中心点
			cityname: '', //当前城市
			cname: '',  //点击查询后当前城市传入地图组建的字段
			pickerOptions: {
				shortcuts: [{
					text: '近一月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '近三月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: '近六月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: '近一年',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			cascaderProp: {
				multiple: true,
				emitPath: false
				// checkStrictly:true
			},
			citylist: [],
			mode: 'map',//选中的模式
			// mode: 'table',//选中的模式  map 地图模式  table 表格模式
			condition: {
				city_plate_id: [14, 16, 18, 73, 74],
				usecate: [],
				// year: 1,
				search: '',
				tradedate: []
			},
			headerData: [], //表头数据
			arealist: [],
			usecate: [],
			yearlist: [],
			allFileddata: [],
			table: {},

			pagesize: 30, //默认分页条数
			pagesizes: [30, 50, 100, 200], //分页条数选择
			currentpage: 1, //当前页默认1
			total: 0, //总条数

			totaldata: {
				acreage: 0,
				buildacreage: 0,
				totalprice: 0,
				floorprice: 0,
				overflow: '0%'
			},
			where: {}, //接受地图组建经纬度条件，
			changeCenter: 0,//0-1之间的随机数用于触发地图中心点改变 从而请求数据
			mapdata: [],//地图请求的数据  传入地图组建
			mapcenter: {},// 地图中心点
			showmap: false,
			polygondata: [], //多边形数据
			searchData: [] //搜索数据

		}
	},


	computed: {
		currentzoom: {
			get() {
				return this.$store.state.currentzoom
			},
			set(v) {
				this.$store.commit('currentzoom', v)
			}
		}
	},
	mounted() {

		Promise.all(
			[
				//初始化城市区域列表 
				this.getBigdataCity(),

				// this.getCurrentAreaByID(this.shi),
				//初始化用途
				this.getUseCate(),
				//初始化年限
				// this.getUseYear(),
				//初始化表头选择数据
				// this.getFiledData()
			]
		).then(res => {
			this.getData()

		})

	},
	watch: {
		//监听城市选择数据变化 获取当前的城市 用于地图初始化中心点
		'condition.city_plate_id': {
			handler(newvalue) {

				const areaid = newvalue[0];

				const cname = this.getCityidBychild(areaid)

				this.cityname = cname

			},
			deep: true,
			immediate: true
		},
		//监听表单切换
		mode: {
			handler(nv) {
				this.getData()
				// switch(nv){
				// 	case 'map':

				// 		break;
				// 	case 'table':

				// 		break;
				// 	default:
				// 		break;	
				// }

			},
			deep: true
		},
		//初始化获取当前表头字段
		allFileddata: {
			immediate: true,
			handler(newValue, oldValue) {
				let header = []
				newValue.filter(item => {
					if (item.checked) {
						header.push(item.field)
					}
				})
				this.headerData = header
			}

		},

		currentzoom: {
			handler(v) {
				this.getMapData()
			},
			deep: true
			}
	},

	components: {
		Breadcrumb,
		TableData,
		SelectForm,
		Mapmode,
		leafletmapmode
	},
	methods: {
		//表格位置点击事件
		tolocation(id) {
			this.mode = 'map'

			getDetail({ id: id ,is_gaode:isgaode}).then(res => {
				// console.log(res)
				const data = res.data
				if (data != null) {


					this.singledata = data
				}

			})
		},
		//获取地图的四点条件	
		getBounds(bounds) {
			this.where = bounds
			this.getPolygonData()
		},

		//获取2级的城市id
		getCityidBychild(id) {
			let res = ''
			// console.log('citylist', this.citylist)
			this.citylist.map(level1 => {
				if (level1.children) {

					level1.children.map(level2 => {
						const name = level2.label
						// alert(name)
						if (level2.children) {
							level2.children.some(level3 => {

								if (level3.value === id) {
									res = name
									return true
								}
								if (level3.children) {
									level3.children.some(level4 => {
										if (level4.value === id) {
											res = name
											return true
										}
									})
								}


							})
						}
					})
				}
			})
			return res;
		},



		getPolygonData() {



			let params = {}
			if (this.type === 3) {
				params = {
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					// search: this.condition.search,
					is_gaode:isgaode,
					tradedate: JSON.stringify(this.condition.tradedate),
					...this.where  //获取经纬度

				}

			} else {
				params = {
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					is_gaode:isgaode,
					// search: this.condition.search,
					...this.where  //获取经纬度

				}
			}

			//获取地图数据
			mapData(params).then(res => {
				this.polygondata = res.data
			})

		},


		//获取地图模式数据
		// getMapData() {



		// 	let params = {}
		// 	if (this.type === 3) {
		// 		params = {
		// 			type: this.type,
		// 			city_plate_id: JSON.stringify(this.condition.city_plate_id),
		// 			usecate: JSON.stringify(this.condition.usecate),
		// 			// search: this.condition.search,

		// 			tradedate: JSON.stringify(this.condition.tradedate),
		// 			...this.where  //获取经纬度

		// 		}

		// 	} else {
		// 		params = {
		// 			type: this.type,
		// 			city_plate_id: JSON.stringify(this.condition.city_plate_id),
		// 			usecate: JSON.stringify(this.condition.usecate),
		// 			// search: this.condition.search

		// 		}
		// 	}


		// 	// console.log('params', params)
		// 	//获取地图数据
		// 	mapData(params).then(res => {
		// 		this.mapdata = res.data
		// 		if (this.condition.search) {
		// 			this.getSearchData()
		// 		}
		// 	})
		// },


		//获取地图模式数据
		getMapData() {

			let params = {}
			if (this.type === 3) {
				params = {
					is_gaode:isgaode,
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					// search: this.condition.search,

					tradedate: JSON.stringify(this.condition.tradedate),
					...this.where  //获取经纬度
				}

			} else {
				params = {
					is_gaode:isgaode,
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					// search: this.condition.search,
					...this.where  //获取经纬度
				}
			}
			if (this.type === 2 || this.type === 3) {
				params.affordtype = this.condition.affordtype
			}


			//获取地图数据

			if (this.currentzoom >= 13) {
				this.areaCountData = []
				mapData(params).then(res => {
					this.mapdata = res.data
					if (this.condition.search) {
						this.getSearchData()
					}
				})

			} else {
				this.mapdata = []
				mapCount(params).then(res => {
					this.areaCountData = res.data
				})
			}
		},

		getSearchData() {

			let params = {}
			if (this.type === 3) {
				params = {
					is_gaode:isgaode,
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					search: this.condition.search,

					tradedate: JSON.stringify(this.condition.tradedate),

				}

			} else {
				params = {
					is_gaode:isgaode,
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					search: this.condition.search

				}
			}

			// console.log('params', params)
			//获取地图数据
			mapData(params).then(res => {
				// console.log('====',res.data)
				if (JSON.stringify(res.data) === '[]') {
					this.$message.info('没有找到相关数据信息')
				} else {
					this.searchData = res.data

				}

			})
		},


		//获取查询的数据 根据地图模式和表单模式不同，获取不同的数据
		getData() {
			if (this.mode === 'map') {
				this.mapdata = []
				this.initMapData()
			}

		},




		//地图模式下点击查询按钮
		async initMapData() {


			const firstCityID = this.condition.city_plate_id[0]


			//获取中心点
			const mapcenter = await this.getCityCenter(firstCityID)
			this.mapcenter = mapcenter
			this.getMapData()



		},

		getCityCenter(id) {
			return new Promise(function (resolve, reject) {
				getCityLnglat({ dcity_id: id }).then(res => {
					// resolve([res.data.lng, res.data.lat])
					resolve({ lng: res.data.lng, lat: res.data.lat })
				})
			})
		},



		//导出excel
		exportExcel() {
			let params = {}

			params = {
				city_plate_id: JSON.stringify(this.condition.city_plate_id),
				usecate: JSON.stringify(this.condition.usecate),
				// year: this.condition.year,
				tableHeader: JSON.stringify(this.headerData)
			}



		},

		//获取大数据city
		getBigdataCity() {
			var that = this
			return new Promise(function (resolve, reject) {
				citylist().then(res => {
					that.citylist = res.data
					return resolve(res.data)
				})
			})
		},


		//获取用途
		getUseCate() {
			var that = this
			return new Promise(function (resolve, reject) {
				useCateList().then(res => {
					that.condition.usecate = [res.data[0].id, res.data[1].id, res.data[2].id, res.data[3].id]  //获取第一个为默认
					that.usecate = res.data
					return resolve(res.data)
				})
			})
		},

		//获取年
		// getUseYear() {
		// 	var that = this
		// 	return new Promise(function (resolve, reject) {
		// 		useYearList().then(res => {
		// 			that.condition.year = res.data[0].id  //获取第一个为默认
		// 			that.yearlist = res.data
		// 			return resolve(res.data)p
		// 		})
		// 	})
		// },


		//获取下级城市列表 
		getCurrentAreaByID(id) {
			var that = this
			return new Promise(function (resolve, reject) {
				nextLevelCity({ city_plate_id: id }).then(res => {
					//初始化条件为第一个
					that.condition.city_plate_id = that.condition.city_plate_id === 0 ? res.data[0].city_plate_id : that.condition.city_plate_id
					that.arealist = res.data
					resolve(res.data)
				})
			})
		},

		currentdata(v) {
			this.headerData = v
		}
	}

}


</script>
<style lang="scss" scoped>
.icon {
	height: 16px;
	width: 16px;
	display: inline-block;
	position: relative;
	top: 3px;
	margin-right: 2px;
}

.dtms {
	background: url('/pic/dtms1.png') no-repeat center center;
	background-size: cover;
}

.dtmsChecked {
	background: url('/pic/dtms2.png') no-repeat center center;
	background-size: cover;
}

.bdms {
	background: url('/pic/bdms1.png') no-repeat center center;
	background-size: cover;
}

.bdmsChecked {
	background: url('/pic/bdms2.png') no-repeat center center;
	background-size: cover;
}

.cjfx {
	background: url('/pic/cjfx1.png') no-repeat center center;
	background-size: cover;
}

.cjfxChecked {
	background: url('/pic/cjfx2.png') no-repeat center center;
	background-size: cover;
}

.conContainer {
	flex: 1;
	overflow: hidden;


	.modemenu {
		margin: 0 1%;

		.el-tabs__nav-wrap::after {
			background-color: #fff;
		}
	}

	// .conditionBox {
	// 	margin-top: 10px;
	// 	width: 98%;
	// 	margin-left: 1%;
	// 	height: 54px;
	// 	background: #F0F3F8;
	// 	border-radius: 4px;
	// 	display: flex;
	// 	justify-content: flex-start;
	// 	align-items: center;

	// 	.el-checkbox {
	// 		margin-right: 10px;
	// 	}

	// 	.el-form-item {
	// 		margin: 0;
	// 		margin-left: 30px;
	// 	}

	// 	.el-form--inline .el-form-item__label {
	// 		font-weight: 600;
	// 		font-size: 16px;
	// 	}

	// }

	.conditionBox {
		margin-top: 10px;

		width: 98%;
		margin-left: 1%;
		min-height: 54px;
		max-height: 100px;
		background: #F0F3F8;
		border-radius: 4px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;

		.demo-form-inline {
			display: flex;
			flex-wrap: wrap;
			padding: 10px;
		}

		.el-checkbox {
			margin-right: 10px;
		}

		.el-form-item {
			margin: 0;
			margin-left: 10px;
		}

		.el-form--inline .el-form-item__label {
			font-weight: 600;
			font-size: 16px;
		}

	}

	.map,
	.anylise {
		width: 98%;
		height: calc(100vh - 220px);
		overflow: hidden;
	}

	.map {
		margin: 1%;
	}

	.table {
		width: 98%;
		margin: 0 1%;

		.selectbtn {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.btnleft {
				display: flex;

				.totaltag {
					margin-left: 20px;

					.el-tag {
						margin-left: 5px;
					}
				}
			}

		}
	}

}
</style>  